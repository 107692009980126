import abi from "./contract/GlobalPool.json";
import abi1 from "./contract1/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./components/Buy";
import Buy1 from "./components/buy1";

import Task  from "./tasks";
import "./style.css";
import "./spcc.png";
import "./copy.png";

import { red } from "@mui/material/colors";
import { DateParam, DateTimeParam } from "use-query-params";


function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [ntasks,setnTasks] = useState([]);

const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");
const [a3,sea3]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x150c0B23524d943b9960e5f12449645775B591BA";
      const contractABI = abi.abi;

      const contractAddress1 = "0x041C33E4700A941c0252416440Ee7012FF0e0253";
      const contract2abi = abi1.abi1;
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0x3eDBcfCE3D321d1e6b4115b305CAa36B7719e634";
  }
  
  try {
    const { ethereum } = window;
    document.querySelector("#test").value = "Enter Amount";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          const contract1 = new ethers.Contract(
            contractAddress1,
            contract2abi,
            signer
          );

          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      let alltasks1 = await contract.getMemos1(account[0]);
      setTasks1(alltasks1);
      let nalltasks = await contract1.getMemos1(account[0]);
      setnTasks(nalltasks);
      const a3 = Number(nalltasks[2])/1000000000000000000;
          
      sea3(a3);

      document.querySelector("#bal1").value = Number(nalltasks[2])/1000000000000000000;
      const b =Number(alltasks[2]);
      const refer = (alltasks[1]);
     setrefer(refer);
     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = Number(alltasks[3])/1000000000000000000;
     setDirects(directs);
     const level = Number(alltasks[4])/1000000000000000000;
     setLevel(level);
     
    
     const pack = Number(alltasks[5]) / 1000;
     document.querySelector("#pp").value  = Number(alltasks[5]);
     

setpackage(pack);



     setB(b);

     
     const upd = (Number(alltasks[6])/1000 )   ;
          
     setupd(upd);

     const dd = Number(alltasks[7]);
          
     setDD(dd);

     const dd2 = Number(alltasks[8]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3600 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       

       
    // const roi1 = Number(alltasks[9]) / 100000;
          
    // seroi1(roi1);

    

    // document.querySelector("#wi1").value = roi2;
     
     const a1 = Number(alltasks1[0])-1;
          
     sea1(a1);

     const a2 = Number(alltasks1[1])/1000000000000000000;
     sea2(a2);   
     
     var rr = 0;
     
     if(pack>0)
     {
      rr = 0.003; 
     }
     
     if(pack > 100)
     {
      rr = 0.0035;
      
     }
     
     if(pack > 200)
     {
      rr = 0.0040;
        }
     if(pack > 400)
     {
      rr = 0.0045;
      
     }
     

     if(pack > 800)
     {
      rr = 0.0050;
      
     }
     if(pack > 1600)
     {
      rr = 0.0055;
      
     }
     if(pack > 5000)
     {
      rr = 0.0060;
           }
     
   const roi = tt1 * (pack * rr);
        
   seroi(roi);

     const tt =  level + roi + directs;
     settt(tt);
     const tt3 = tt - upd ;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://spctoken.in/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;

console.log(pack);
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>
    <input type="hidden" id ="bal1"></input>
<div class="se-pre-con"></div>
    <div class="wrapper"  style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
       
        <nav id="sidebar" style={{
                      backgroundColor: "white"}}>
            <div class="sidebar-header" >
                <h1>
                    <a ><img src ="spcc.png" width="120px" height="120px"></img></a>
                </h1>
              
            </div>
            <div>
          
            </div> </nav></div>
            <div id="content">
            
      <div className="container">
       
   
</div></div>
<div class="container-fluid">

<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">TOTAL SPC TOKEN STAKED</p>
                            </div>
                           {a2}
                           <br></br><br></br>
                        </div>
    </div>
    </div> <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">1 SPC = {a3} USDT
                            </p>
                            </div>
                           
                           <br></br><br></br>
                        </div>
    </div>
    </div>

    <br></br><br></br>  
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">TOTAL STAKE HOLDERS</p>
                            </div>
                           {a1}
                           <br></br><br></br>
                        </div>
    </div>
    </div>

    <br></br><br></br>      <div class="row">
                   
                    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">Wallet Address</p>
                            </div>
                           
                            Connected Account - {account}
                           <br></br><br></br>
                        </div>
    </div>
    </div>


<br></br><br></br>


    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">My Refer</p>
                            </div>
                           {refer}
                           <br></br><br></br>
                        </div>
    </div>
    </div>
    <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                               <br></br> <br></br>
                                <p class="paragraph-agileits-w3layouts text-white">My Refer Link</p>
                            </div>
                           {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button>
         <br></br><br></br>
                        </div>
    </div>
    </div>
    <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">MY STAKING</p>
                            </div>
                             {pack} SPC<br></br><br></br>
                        </div>
    </div>
    </div>
<br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">STAKING SINCE</p>
                            </div>
                             {tt1} DAYS <br></br><br></br>
                        </div>
    </div>
    </div> <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">MY DIRECT TEAM</p>
                            </div>
          
                             {direct}
                             <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                               <br></br><br></br>  
                                <p class="paragraph-agileits-w3layouts text-white">MY DIRECT INCOME</p>
                            </div>
                             {directs} SPC<br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">MY LEVEL INCOME</p>
                            </div>
                             {level} SPC
                            <br></br><br></br>                        </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">STAKING INCOME</p>
                            </div>
                             {roi} SPC
                            
                            <br></br><br></br>                       </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">TOTAL BONUS</p>
                            </div>
                             {tt} SPC  <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>     

<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">TOTAL WITHDRAW</p>
                            </div>
                             {upd} SPC  <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>     
<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">TOTAL PENDING</p>
                            </div>
                             {tt3} SPC  <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>    
 <div class="row">
    
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                <p class="paragraph-agileits-w3layouts text-white">STAKE</p>
                            </div>
                            <input type ="text" id ="test" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy state={state}  /> <br></br><br></br>
                        </div>
    </div>
    </div>

    <br></br><br></br>    
 
<br></br><br></br> </div>
  
    </div>
  
  
  );
}

export default App;